import React, { Suspense, useCallback, useEffect, useRef } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  useLocation,
  Redirect,
  RouteProps,
} from 'react-router-dom';

import { AuthProvider, useAuth } from './auth/AuthContext';
import { WelcomePage } from './welcome/WelcomePage';
import { AssetsManagementPage } from './assets-management/AssetsManagementPage';
import { ThemeProvider } from './ui/theme';
import { SnackbarProvider } from './ui/SnackbarContext';
import { composeComponents } from './core/composeComponents';
import { PushNotificationsProvider } from './core/PushNotificationsContext';
import { FeatureFlagsProvider } from './core/FeatureFlagsContext';
import { TeamManagementPage } from './team-management/TeamManagementPage';
import { EnvironmentInfo } from './core/EnvironmentInfoContext';
import { ConcurrentUsersPage } from './auth/ConcurrentUsersPage';
import { SettingsPage } from './core/SettingPage';
import { URLS_ENUM } from './url/url-builder';
import { ProjectLoader } from './ui/ProjectPageLoader';
import useAsyncEffect from './core/useAsyncEffect';

import { ToursProvider, useTours } from './tour/ToursContext';
import { addBasePathToPath, getBasePrefixURL } from './core/api-client';
import { TokenData, useAuthProvider } from './auth/AuthProvider';
import { AuthorizationRequestPage } from './auth/AuthorizationRequestPage';

const GlobalProviders = composeComponents(
  ToursProvider,
  ThemeProvider,
  AuthProvider,
  SnackbarProvider,
  PushNotificationsProvider,
  FeatureFlagsProvider,
  EnvironmentInfo
);

const WelcomeTrialPage = React.lazy(
  () => import('./trial/welcome/WelcomeTrialPage')
);
const FeatureFlagsPage = React.lazy(() => import('./core/FeatureFlagsPage'));
const ServerDownPage = React.lazy(() => import('./auth/ServerDownPage'));
const UnauthorizedUser = React.lazy(() => import('./auth/UnauthorizedUser'));

function SwitchRoutes() {
  const { user, error: authError, login, isUserLoggedIn } = useAuth();
  const isLoginRef = useRef(false);
  const authProvider = useAuthProvider();

  const handleLogin = useCallback(
    async (idTokenParsed: TokenData) => {
      const { email, lastName, userName } = idTokenParsed;

      await login({ email, lastName, userName, firstName: userName });

      if (Notification.permission === 'default') {
        Notification.requestPermission().then((permission) => {
          if (permission !== 'granted') {
            console.warn('Notification permission not granted');
          }
        });
      }
    },
    [login]
  );

  useAsyncEffect(async () => {
    if (
      authProvider?.authenticated &&
      authProvider?.tokenParsed &&
      !isUserLoggedIn &&
      isLoginRef.current === false
    ) {
      isLoginRef.current = true;
      await handleLogin(authProvider.tokenParsed);
    }
  }, [
    handleLogin,
    authProvider?.authenticated,
    authProvider?.tokenParsed,
    isUserLoggedIn,
  ]);

  if (!isUserLoggedIn && !authError) {
    return <h3>Loading...</h3>;
  }

  return (
    <Switch>
      {(authError?.status ?? 0) === 401 && (
        <Route>
          <Suspense fallback={<h3>Loading...</h3>}>
            <UnauthorizedUser />
          </Suspense>
        </Route>
      )}
      {(authError?.status ?? 0) >= 500 && (
        <Route>
          <Suspense fallback={<h3>Loading...</h3>}>
            <ServerDownPage />
          </Suspense>
        </Route>
      )}
      {user?.singleUserMode?.trialStarted === false && (
        <>
          <Route path={URLS_ENUM.TRIAL}>
            <Suspense fallback={<h3>Loading...</h3>}>
              <WelcomeTrialPage />
            </Suspense>
          </Route>
          <Redirect to={URLS_ENUM.TRIAL} />
        </>
      )}
      <PrivateRoute path={URLS_ENUM.CONFLICT_USERS}>
        <ConcurrentUsersPage />
      </PrivateRoute>

      <PrivateRoute path={URLS_ENUM.AUTHORIZATION_REQUEST}>
        <AuthorizationRequestPage />
      </PrivateRoute>

      <PrivateRoute path={URLS_ENUM.WELCOME}>
        <WelcomePage />
      </PrivateRoute>

      <PrivateRoute path={URLS_ENUM.FLAGS}>
        <Suspense fallback={<h3>Loading feature flags...</h3>}>
          <FeatureFlagsPage />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute path={URLS_ENUM.ASSETS}>
        <AssetsManagementPage />
      </PrivateRoute>

      <PrivateRoute path={URLS_ENUM.SETTINGS}>
        <SettingsPage />
      </PrivateRoute>

      <AdminRoute path={URLS_ENUM.TEAM_MANAGEMENT}>
        <TeamManagementPage />
      </AdminRoute>

      <PrivateRoute path={URLS_ENUM.PROJECT}>
        <ProjectLoader />
      </PrivateRoute>

      <Redirect from="/" to={URLS_ENUM.PROJECT} />

      <Route path="*" component={NoMatch} />
    </Switch>
  );
}

function App() {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const serviceWorkerPath = addBasePathToPath('/service-worker.js');
      navigator.serviceWorker.register(serviceWorkerPath).catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
    }
  }, []);

  return (
    <BrowserRouter basename={getBasePrefixURL()}>
      <GlobalProviders>
        <SwitchRoutesAndTours />
      </GlobalProviders>
    </BrowserRouter>
  );
}

function SwitchRoutesAndTours() {
  const { Tour } = useTours();

  return (
    <>
      <SwitchRoutes />
      {Tour}
    </>
  );
}

function NoMatch() {
  const { pathname } = useLocation();

  return (
    <div>
      <h3>
        404 - <code>{pathname}</code>
      </h3>
    </div>
  );
}

function PrivateRoute({ children, ...rest }: RouteProps) {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: URLS_ENUM.LOGIN,
                state: { from: location },
              }}
            />
          );
        }

        return children;
      }}
    />
  );
}

function AdminRoute({ children, ...rest }: RouteProps) {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user?.role !== 'admin') {
          return (
            <Redirect
              to={{
                pathname: URLS_ENUM.WELCOME,
                state: { from: location },
              }}
            />
          );
        }

        return children;
      }}
    />
  );
}

export default App;
